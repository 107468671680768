<template>
    <div class="const_width">
        <div class="content_container overflow-x-hidden">
            <div class="header-container">
                <div class="header-content py-15">
                    <v-row>
                        <v-col cols="12" md="5" class="ml-auto py-8">
                            <h3 class="headline font-weight-regular _c1--text">Premiumlu Ol,</h3>
                            <h1 class="display-1 font-weight-medium white--text">Bahis Kazançlarını Maksimuma
                                Çıkar!</h1>
                        </v-col>
                        <v-col cols="12" md="3" class="mr-auto py-8">
                            <v-sheet color="#00000066" class="rounded pa-3 ml-auto">
                                <v-card-subtitle class="py-0 grey--text text-decoration-line-through">
                                    £ 14.99 /Ay
                                </v-card-subtitle>
                                <v-card-title class="py-0 white--text mb-2 d-flex ml-auto">
                                    £ 9.99 /Ay
                                </v-card-title>
                                <v-btn
                                    color="_c1"
                                    class="white--text"
                                    block
                                    :to="{name: 'Payment'}"
                                >
                                    PREMIUMLU OL
                                </v-btn>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </div>
                <div class="bg-image"></div>
                <div class="bg-image-filter"></div>
            </div>
            <v-container>
                <h1 class="text-center _accent--text">Premium Özellikleri</h1>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-card flat outlined>
                            <v-img
                                height="150"
                                src="@/assets/premium/pbg-1.jpg"
                            ></v-img>
                            <v-card-text>
                                <div>
                                    FV-Tips yapay zeka algoritması ile maç önü ve canlı bahis tahminleri.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card flat outlined>
                            <v-img
                                height="150"
                                src="@/assets/premium/pbg-4.png"
                            ></v-img>
                            <v-card-text>
                                <div>
                                    Profesyoneller için detaylı canlı maç istatistikleri & oran değişimleri.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card flat outlined>
                            <v-img
                                height="150"
                                src="@/assets/premium/premium-header.jpg"
                            ></v-img>
                            <v-card-text>
                                <div>
                                    Bahis oyuncuları için maç sonuçları & gol, korner, kart yüzdeleri.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card flat outlined>
                            <v-img
                                position="bottom"
                                height="150"
                                src="@/assets/premium/pbg-2.jpg"
                            ></v-img>
                            <v-card-text>
                                <div>
                                    İddaa Radar: Canlı bahis takip sistemi.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card flat outlined>
                            <v-img
                                position="top"
                                height="150"
                                src="@/assets/premium/pbg-3.jpg"
                            ></v-img>
                            <v-card-text>
                                <div>
                                    Günün gol, korner ve kart istatistiği en yüksek maçları.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card flat outlined>
                            <v-img
                                height="150"
                                src="@/assets/premium/pbg-5.png"
                            ></v-img>
                            <v-card-text>
                                <div>
                                    Gol, korner, kart tahminleri.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
    name: "PremiumInfo",
}
</script>

<style scoped>
.header-container {
    position: relative;
    width: 100%;
}

.bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/premium/premium-header.jpg");
    background-position: center;
    background-size: cover;
    z-index: 0;
    top: 0;
}

.bg-image-filter {
    position: absolute;
    background-color: #00000077;
    width: 100%;
    height: 100%;
    top: 0;
}

.header-content {
    position: relative;
    width: 100%;
    padding: 12px;
    z-index: 1;
}

li {
    font-size: 1.2rem;
}
</style>
